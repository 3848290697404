@tailwind base;
@tailwind components;
@tailwind utilities;

         
.btn-grad {
  background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA  51%, #1FA2FF  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn {
  background-image: linear-gradient(to right, #00B4DB 0%, #1FA2FF   51%, #1FA2FF  100%);
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0px 4.42184px 107.23px rgba(86, 139, 255, 0.51);
}
.btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #00B4DB 21.43%,
    #1FA2FF 50.63%,
    #1FA2FF 100%,
    #122b86 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#5186f1] to-[#2694f4] font-bold font-primary hover:from-[#1f4037] hover:to-[#99f2c8];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}

.content-wrap
{
   flex:1
}

/* All fonts mostly from Google 
* 1. Montserrat Light 300 and 400
*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

/* Adding some effects to my icons on my banner page */
.banner {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Adjust the margin-top value as desired */
}

.banner .icon {
  position: relative;
  background: #000000;
  border-radius: 50%;
  padding: 15px;
  margin: 5px; /* Adjust the margin value to make the icons closer together */
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


.banner .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.banner .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.banner .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.banner .icon:hover span,
.banner .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.banner .instagram:hover,
.banner .instagram:hover .tooltip,
.banner .instagram:hover .tooltip::before {
  background: #E4405F;
  color: #ffffff;
}

.banner .whatsapp:hover,
.banner .whatsapp:hover .tooltip,
.banner .whatsapp:hover .tooltip::before {
  background: #25D366;
  color: #ffffff;
}

.banner .github:hover,
.banner .github:hover .tooltip,
.banner .github:hover .tooltip::before {
  background: #333333;
  color: #ffffff;
}

.banner .gmail:hover,
.banner .gmail:hover .tooltip,
.banner .gmail:hover .tooltip::before {
  background: #D44638;
  color: #ffffff;
}

.ueberblenden
{
  filter: blur(5px);
}